import type { Ref } from "vue";

import type { CourseListView } from "~/business-areas/course/composables/course-list.hook";

export const useSearchPageTitle = ({
  results,
  selectedTags,
}: {
  results: Ref<CourseListView[] | null>;
  selectedTags: Ref<DatabaseTable<"tags">[]>;
}) => {
  const i18n = useI18n();

  return computed(() => {
    const numberOfResults = results.value?.length;
    const selectedTagLabels = selectedTags.value.map((tag) => tag.label);

    if (selectedTagLabels.length === 0) {
      return i18n.t("search.page.results_title", {
        count: numberOfResults,
        countValue: `<strong>${numberOfResults}</strong>`,
      });
    }

    if (selectedTagLabels.length > 3)
      return i18n.t("search.page.results_title_with_generic_category", {
        count: numberOfResults,
        countValue: `<strong>${numberOfResults}</strong>`,
      });

    return i18n.t("search.page.results_title_with_category_details", {
      count: numberOfResults,
      countValue: `<strong>${numberOfResults}</strong>`,
      category: `<strong>${useI18nList(selectedTagLabels).value}</strong>`,
    });
  });
};

import { startOfTomorrow } from "date-fns";
import type { LocationQuery } from "vue-router";

import type { CourseFilters } from "~/business-areas/course/composables/course-list.hook";

export type SearchPageFilters = Pick<
  CourseFilters,
  "to" | "from" | "lang" | "tags" | "duration"
>;

export function useDefaultSearchFilters(): Partial<SearchPageFilters> {
  return {
    from: startOfTomorrow().toISOString(),
  };
}

export function useSearchPageFiltersActions(
  filters: Ref<SearchPageFilters>,
  options?: {
    default?: Partial<SearchPageFilters>;
  },
) {
  const { $analytics } = useNuxtApp();

  const reset = () => {
    $analytics.track("search_reset");
    Object.keys(filters.value).forEach((key) => {
      // @ts-expect-error index of filters
      filters.value[key] = options?.default?.[key];
    });
  };

  const hasActiveFilters = computed(() => {
    return numberOfActiveFilters.value > 0;
  });

  const numberOfActiveFilters = computed(() => {
    return Object.entries(filters.value).filter(([key, value]) => {
      if (Array.isArray(value)) return value.length > 0;

      const defaultValue = options?.default?.[key as keyof SearchPageFilters];
      if (defaultValue) return defaultValue !== value;

      return value !== undefined && value !== null;
    }).length;
  });

  return {
    reset,
    hasActiveFilters,
    numberOfActiveFilters,
  };
}

function toSearchPageFilter(
  query: LocationQuery,
  defaultFilters: Partial<SearchPageFilters>,
) {
  return {
    from: (query.from as string) ?? defaultFilters.from,
    to: query.to as string,
    lang: query.lang as string,
    tags: toArray((query.tags ?? []) as string[]),
    duration: query.duration as string,
  };
}

export function useSearchPageFiltersData() {
  const { $analytics, $on } = useNuxtApp();
  const query = useRoute().query;
  const defaultFilters = useDefaultSearchFilters();

  const filters = reactive<SearchPageFilters>(
    toSearchPageFilter(query, defaultFilters),
  );

  watch(
    () => filters,
    () => {
      $analytics.track("search_requested", filters);
      useRouter().push({ query: filters });
    },
    { deep: true },
  );

  if (import.meta.client) {
    $on("search_requested", (query: SearchPageFilters) => {
      filters.tags = query.tags;
    });
  }

  return {
    filters,
  };
}

<template>
  <div>
    <search-page-filters
      v-model="filters"
      :total-number-of-results="data.length"
    />

    <app-page-wrapper class="pt-8" tag="section">
      <search-page-no-results v-show="isEmpty" />

      <div v-show="!isEmpty">
        <div class="mx-auto mb-8">
          <app-skeleton-text
            v-if="status === 'pending' && data.length === 0"
            class="mx-auto bg-primary-200"
            :width="200"
          />
          <h1 v-else class="balance text-center text-xl" v-html="title" />
        </div>

        <course-card-grid
          id="catalog"
          :list="data"
          :loading="status === 'pending' && data.length === 0"
          show-next-session
        />
      </div>
    </app-page-wrapper>

    <app-page-wrapper class="mt-16 flex flex-col gap-16 md:mt-24 md:gap-24">
      <catalog-missing-topic-contact-banner catalog-page-id="search" />

      <brand-customer-logos-banner />

      <brand-nps-banner>
        <template #append>
          <growth-contact-button
            color="dark"
            origin="catalog"
            origin_page_action="nps_banner_cta"
            origin_page_id="search"
            size="xl"
          />
        </template>
      </brand-nps-banner>

      <brand-qualiopi-banner />
    </app-page-wrapper>
  </div>
</template>

<script lang="ts" setup>
import CatalogMissingTopicContactBanner from "~/pages/catalog/_includes/components/catalog-missing-topic-contact-banner.vue";
import SearchPageFilters from "~/pages/catalog/index/search/_includes/components/search-page-filters.vue";
import SearchPageNoResults from "~/pages/catalog/index/search/_includes/components/search-page-no-results.vue";
import { useSearchPageFiltersData } from "~/pages/catalog/index/search/_includes/composables/search-page-filters.data";
import { useSearchPageTitle } from "~/pages/catalog/index/search/_includes/composables/search-page-title.hook";

definePageMeta({
  stickyCatalogNavigation: false,
});

const { filters } = useSearchPageFiltersData();
const { data: tags } = useTagList({ depth: 0, used: true });
const { data, status } = await useCourseList("catalog_next_sessions", filters, {
  lazy: true,
});

const isEmpty = computed(
  () => status.value === "success" && data.value.length === 0,
);

const title = useSearchPageTitle({
  results: data,
  selectedTags: computed(
    () => tags.value?.filter((tag) => filters.tags?.includes(tag.slug)) ?? [],
  ),
});

watch(filters, () => {
  document.documentElement.scrollTop = 0;
});
</script>
